







































































































































import { ESearch, FacetsFilters, Pagination, Scrollbar, SearchTerms, SvgImage, TwSelect } from '@/app/components';
import { PlusIcon } from '@vue-hero-icons/outline';
import { FilterIcon } from '@vue-hero-icons/solid';
import { defineComponent, ref } from '@vue/composition-api';

import { OrbitSpinner } from 'epic-spinners';
import { FakeRetrievalQueryCard, RetrievalQueryCard } from '../components';
import { useRetrievalQueries } from '../composable';
import { RetrievalQuery } from '../interfaces';

export default defineComponent({
    name: 'RetrievalQueries',
    metaInfo: { title: 'Retrieval' },
    components: {
        PlusIcon,
        OrbitSpinner,
        Pagination,
        RetrievalQueryCard,
        FakeRetrievalQueryCard,
        SvgImage,
        Scrollbar,
        TwSelect,
        ESearch,
        FacetsFilters,
        SearchTerms,
        FilterIcon,
    },
    setup(props, { root }) {
        const uploadRequestedQueries = ref<string[]>([]);
        const openFilters = ref<boolean>(true);
        const pageSize = 10;
        const searchableFields = [
            {
                label: 'title',
                key: 'title.keyword',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description.keyword',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
        ];

        const {
            page,
            query,
            retrievalQueries,
            loading,
            totalResults,
            tab,
            sortBy,
            deleteRetrievalQuery,
            updateCurrentPage,
            sortByChanged,
            sortingFields,
            errors,
            currentSearchedFacetsFilters,
            facetChanged,
            filterChanged,
            clearFilters,
            searchQuery,
            facets,
            retrievalQueryFacets,
            retrievalQueryFilters,
            assetOptions,
            getUploadRequestedQueries,
        } = useRetrievalQueries(root, 'retrieval', pageSize);

        const deleteQuery = (retrievalQuery: RetrievalQuery) => {
            deleteRetrievalQuery(retrievalQuery)
                .then(() => {
                    (root as any).$toastr.s(`Retrieval Query successfully deleted`, 'Successful deletion!');
                })
                .catch((error) => {
                    (root as any).$toastr.e(error, 'Validation error');
                });
        };

        getUploadRequestedQueries().then((rq) => {
            uploadRequestedQueries.value = rq;
        });

        return {
            tab,
            page,
            pageSize,
            loading,
            retrievalQueries,
            totalResults,
            sortingFields,
            query,
            sortBy,
            deleteQuery,
            updateCurrentPage,
            sortByChanged,
            searchableFields,
            errors,
            openFilters,
            currentSearchedFacetsFilters,
            facetChanged,
            filterChanged,
            clearFilters,
            searchQuery,
            facets,
            retrievalQueryFacets,
            retrievalQueryFilters,
            assetOptions,
            uploadRequestedQueries,
        };
    },
});
